import React from 'react';
import {observer} from "mobx-react";
import './ModalSettingRole.scss'
import CardRole from "./CardRole";
import {roomStore} from "../../../room/RoomStore";
import {settingRoleStore} from "../../SettingRoleStore";

const ModalSettingRole = () => {
    return (
        <div className='modal modal_full fade' id='setting_role' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content border-0'>
                    <div className='modal-header px-4'>
                        <div className='d-flex w-100 flex-column modal-header__container'>
                            <div className='header__title'>
                                {settingRoleStore.namePosition}
                            </div>
                            <button type="button"
                                    className="close d-flex align-items-center"
                                    id="close_setting_role"
                                    data-bs-dismiss="modal"
                                    aria-label="Close">
                                <img src="/assets/ico/action/close.svg" alt=""/>
                                <p className="m-0">Đóng</p>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className='w-100 modal-header__content'>
                            {
                                settingRoleStore.listRole.map((item: any, index: number) => {
                                    return (
                                        <CardRole key={index} value={item.value} keyRole={item.keyRole} title={item.title}
                                                  onChange={(key: any, checkedValues: any[]) => {
                                                      item.value = checkedValues
                                                  }}
                                                  onChangeAll={(e: any) => {
                                                          if(e.target.checked) {
                                                              item.value = item.listRole.map((item: any) => {
                                                                  return item.value
                                                              })
                                                          }
                                                          else item.value = []
                                                      }
                                                  }
                                                  listRole={item.listRole}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer p-4">
                                <button type='submit' className='btn btn__save'  disabled={roomStore.typeModal === 'edit' && roomStore.isDisableEditBtn}
                                        onClick={() => console.log(settingRoleStore.listRole)}>Cập nhật</button>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default observer(ModalSettingRole);

