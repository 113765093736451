import humps from 'humps';
import axios, {Method} from "axios";
import $ from 'jquery'
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
    readonly headers?: any
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
}
export function clearSettingLocalStorage(){
    localStorage.clear()
    StorageService.removeToken();
    StorageService.removeRefreshToken();
    window.location.href = '/login'
}

let apiRefreshToken = process.env.REACT_APP_API_SSO_DOMAIN + '/sso/v1/auth/refresh-token'
// @ts-ignore
const refreshAuthLogic = (failedRequest: { config: { headers: any } }) => axios.post(apiRefreshToken, {
    'refreshToken': StorageService.getRefreshToken(),
    service: 'iss'
})
    .then(response => {
        let {token, claims} = response.data
        parseInt(token)
        localStorage.setItem('listRole', claims.roles)
        localStorage.setItem('name', claims.name ?? claims.username)
        localStorage.setItem('avatar', claims.avatar)
        localStorage.setItem('statusAccount', claims.status)
        StorageService.setToken(token);
        failedRequest.config.headers['Authorization'] = 'Bearer ' + token;
        return Promise.resolve();
    })
    .catch(function (error) {
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});



let API_DOMAIN = process.env.REACT_APP_API_DOMAIN
let API_SSO_DOMAIN = process.env.REACT_APP_API_SSO_DOMAIN

export async function getRequest(path: string, isDomainSso?: boolean): Promise<IApiResponse> {
    var newHeaders: any;

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }else {
        newHeaders = {'Content-Type': 'application/json'}
    }


    return await axios.get((isDomainSso ? API_SSO_DOMAIN  : API_DOMAIN) + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                    headers: response.headers
                };
                return apiResponse;
            },
            (error) => {
                if (error.response && error.response?.status === HttpStatusCode.UNAUTHORIZED) {
                }else if(error.response &&  error.response.status === HttpStatusCode.FORBIDDEN){
                    toastUtil.error('Không có quyền truy cập', 2);
                }

                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response.data?.errorCode,
                        message: error.response.data?.message
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };

                return apiResponse;
            },
        )
}


export async function postRequest(path: string, params: object, isDomainSso?: boolean): Promise<IApiResponse> {
    return apiCall(path, "POST", params, isDomainSso);
}

export function apiCall(path: string, _method: Method = "POST", _params: object, isDomainSso?: boolean): Promise<IApiResponse> {
    var newHeaders: any;

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }else {
        newHeaders = {'Content-Type': 'application/json'}
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: (isDomainSso ? API_SSO_DOMAIN : API_DOMAIN) + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                if (error.response && error.response?.status === HttpStatusCode.UNAUTHORIZED) {

                }else if(error.response &&  error.response?.status === HttpStatusCode.FORBIDDEN){
                    toastUtil.error('Tài khoản của ban không có quyền', 2);
                }

                let bodyError: IBodyError;
                try {
                    if (error.response && error.response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                        bodyError = {
                            errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                            message: "Internal server error, please try again later"
                        }
                    } else {
                        bodyError = {
                            errorCode: error.response?.data?.errorCode,
                            message: error.response?.data?.message
                        }
                    }

                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };

                resolve(apiResponse);
            });

    });
}


export async function putRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PUT", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {

    return apiCall(path, "DELETE", params);
}

export async function exportRequest(path: string, name: string): Promise<any> {
    let newHeaders: any = {
        'Content-Type': 'text/html',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    };

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken();
    }

    return await axios.get(API_DOMAIN + path, {headers: newHeaders, responseType: "blob"})
        .then(
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                console.log(response)
                link.setAttribute('download', `${name}.xlsx`)
                document.body.appendChild(link)
                link.click();
                toastUtil.success('Xuất file thành công!', 1);
            },
            (error) => {
                toastUtil.error(error.response.data.message)
            }
        );
}


export async function uploadExcel(params: object): Promise<IApiResponse> {
    let newHeaders: any = {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    };

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken();
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            method: "POST",
            url: API_DOMAIN + '/farm/v1/upload/excel',
            data: params,
            headers: newHeaders,
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });

            })
            .catch(function (error) {
                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                        message: error.response.data.message
                    }
                } catch (e) {

                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }
                const apiResponse: IApiResponse = {
                    status: error.response.status,
                    body: bodyError
                };
                resolve(apiResponse);
            });
    });
}


export const importData = async (e: any, callBack: any) => {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]
    await exportExcel(file, callBack);
    reader.readAsDataURL(file);
    $('#import_data').val('');
}


export async function exportExcel(files: any, callBack: any) {
    var formData = new FormData();
    formData.append("file", files);
    const result = await uploadExcel(formData);
    if (result.status === HttpStatusCode.OK) {
        await callBack(result.body.filePath);
    } else {
        toastUtil.error(result.body.message);
    }
}


